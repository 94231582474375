export const companyRequiredFieldList: string[] = [
    'companyType', 
    'countryCode'    ,  
];

export const CompanyConstant = {    
    Header: {
        COMPANY: 'Company',
        COMPANY_MAIN: 'Company Maintenance',
        COMPANY_CODE: 'Company Code',
        COMPANY_NAME: 'Company Name',
        ACTIVE_IND: 'Active Ind.',
        COMPANY_TYPE: 'Company Type',
        MASTER_COMPANY: 'Master Company',
        ALLIANCE: 'Alliance',
        ADDRESS_1: 'Address 1',
        ADDRESS_2: 'Address 2',
        ADDRESS_3: 'Address 3',
        STATUS: 'Status',
        SIGN_ON_DATE: 'Sign On Date',
        COUNTRY_CODE: 'Country Code',        
    },
    Detail:{
        ADDRESS: 'Address',
        PRIORITY: 'Priority',
        EFFECTIVE_DATE: 'Effective Date',
        EFFECTIVE_DATE_RANGE: 'Effective Date Range',
        OPERATING_TML: 'Operating Tml',
        STATUS: 'Status',
        CNTR_MOVEMENT: 'Container Movement',
        CNTR_STATUS: 'Container Status',
        CHARGE_TYPE: 'Charge Type',
        SUB_CHARGE_TYPE: 'Sub Charge Type',
        REMARKS: 'Remarks',
        REJECT_REASON: 'Reject Reason',
        ACTIVE_IND: 'Active Ind',
        UOM: 'UOM',
        CONFIRM_DATE:'Confirm Date',
        EMAIL: 'Email',
        CONTACT_PERSON: 'Contact Person',
        MOBILE_PHONE_NO: 'Mobile Phone No.',
        SIGN_ON_DATE: 'Sign On Date',
        AGENT_NO: 'Agent No.',
        NTN_NO: 'NTN No.',
        GST_NO: 'GST No.',
        LET_PASS_ACCOUNT: 'Let Pass Account',
        LET_PASS_ACCOUNT_BALANCE: 'Let Pass Account Balance',
        CONTRACT_TML: 'Contract Tml',
        TARIFF_NATURE: 'Tariff Nature',
        CUSTOMER_TYPE: 'Customer Type',

        CHARGE_SUPPLE_TERMS: {
            CHG_SUPPLE_TERMS: 'Charge Supplementary Terms',
            SUPPLEMENTARY_TERMS: 'Supplementary Terms',
            SUPPLEMENTARY_TERMS_REF: 'Supplementary Terms Ref.',
            MODALITY: 'Modality',
            VALUE: 'Value'
        },
        FSP: {
            COMPANY_FSP: 'Company FSP',
            CONSORTIUM_CODE: 'Consortium Code',
            VESSEL_CODE: 'Vessel Code',
            VOYAGE_CODE: 'Voyage Code',
            SERVICE_CODE: 'Service Code',
            SHIPMENT_TYPE: 'Shipment Type',
            FIRST_TIER_FSP: '1st Tier FSP',
            SECOND_TIER_FSP: '2nd Tier FSP',
            CARGO_CODE: 'Cargo Code',
            GRACE_HOUR_MIN: 'Grace Hour/Min',
            GRACE_HOUR: 'Grace Hour',
            GRACE_MIMUTE: 'Grace Minute',
            FSP_COUNTING_FROM: 'FSP Counting From',
            FSP_COUNTING_TO: 'FSP Counting To',
            DIRECTION: 'Direction',
            CALENDAR_ADJ: 'Calendar Adj.',
            OPS_LINE: 'Ops Line',
            FORWARDER_CODE: 'Forwarder Code',
            FORWARDER_REF: 'Forwarder Ref'
        },
        CUSTOMER: {
            CUSTOMER_ASSIGN: 'Customer Assignment',
            INTERCOMPANY_CODE: 'Intercompany Code',
            CUSTOMER_CODE: 'Customer Code',
            CURRENCY_CODE: 'Currency Code',
            CONTACT_PERSON: 'Contact Person',
            TYPE: 'Type',
            CUSTOMER_NTN_NO: 'Customer NTN No',
            CUSTOMER_GST_NO: 'Customer GST No',
            CUSTOMER_ADDRESS: 'Customer Address',
            CUSTOMER_EMAIL_ADDRESS: 'Customer Email Address',
            CUSTOMER_NAME_1: 'Customer Name1',
            CUSTOMER_NAME_2: 'Customer Name2',
            N_I_F_NUMBER: 'N.I.F Number',
            PAYMENT_TERM: 'Payment Term',
            INTEREST_RATE: 'Interest Rate',
            PAYMENT_CUT_OFF_DAY_1: 'Payment Cut Off Day1',
            PAYMENT_CUT_OFF_DAY_2: 'Payment Cut Off Day2',
            PAYMENT_CUT_OFF_DAY_3: 'Payment Cut Off Day3',
            STATEMENT_ADDRESS_1: 'Statement Address1',
            STATEMENT_ADDRESS_2: 'Statement Address2',
            STATEMENT_ADDRESS_3: 'Statement Address3',
            BILLING_ADDRESS_1: 'Billing Address1',
            BILLING_ADDRESS_2: 'Billing Address2',
            BILLING_ADDRESS_3: 'Billing Address3',
            BILLING_ADDRESS_4: 'Billing Address4',
            CITY: 'City',
            COUNTY: 'County',
            STATE: 'State',
            PROVINCE: 'Province',
            POSTAL_CODE: 'Postal Code',
            COUNTRY: 'Country',
            TEL: 'Tel',
            FAX: 'Fax',
            GL_ACCOUNT_CODE: 'GL Account Code',
            CREDIT_LIMIT: 'Credit Limit',
            OUTSTANDING_BALANCE: 'Outstanding Balance',
            NET_BALANCE: 'Net Balance',
            UN_FINALIZED_AMOUNT: 'Un-Finalized Amount',
            CREDIT_LIMIT_EXCEEDED: 'Credit Limit Exceeded',
            REGION: 'Region',
            SHIPPING_LINE_ADDRESS_1: 'Shipping Line Address1',
            SHIPPING_LINE_ADDRESS_2: 'Shipping Line Address2',
            SHIPPING_LINE_ADDRESS_3: 'Shipping Line Address3'
        },
        EMPTY_POOL: {
            EMPTY_POOL: 'Pool/Empty Pool',
            POOL_TYPE: 'Pool Type',
            CALCULATE_BY: 'Calculate By',
            POOL_SIZE: 'Pool Size',            
        },

        TEU_FACTOR: {
            TEU_FACTOR: 'Teu Factor',
            SIZE: 'Size',
            FACTOR: 'Factor'
        },
    }
}

export const compCustAssignRequiredFieldList: string[] = [
    'intercompanyCode', 
    'effectiveDate',
    'customerId',
    'currencyCode'    
];

export const compEmptyPoolRequiredFieldList: string[] = [
    'poolType', 
    'effectiveDate',
    'poolSize',
    'uom',
    'calculateBy',
    'activeInd'
];

export const compFspRequiredFieldList: string[] = [
    'effectiveDate', 
    'shipmentType',
    'chargeType',
    'uom',
    'firstTierFsp',
    'secondTierFsp',
    'fspCountingFrom',
    'fspCountingTo',
    'direction',
    'activeInd'
];

export const compChgSuppleTermRequiredFieldList_1: string[] = [
    'effectiveDate', 
    'supplementaryTerms',
    'value'
];

export const compChgSuppleTermRequiredFieldList_2: string[] = [
    'effectiveDate', 
    'supplementaryTerms',
    'supplementaryTermsRef'  
];