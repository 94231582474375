import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM";
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffCodeByCompanyDetailPanel from "./Right/TariffCodeByCompanyDetailPanel";
import { TariffCodeByCompanyFormPanel } from "./Right/TariffCodeByCompanyFormPanel";
import TariffCodeByCompanyTablePanel from "./Table/TariffCodeByCompanyTablePanel";

const TariffCodeByCompanyMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffCodeByCompanyState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const {isShowAddPanel, isShowDetail, isBackFromDetail} = tariffCodeByCompanyState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    tariffCodeByCompanyVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {  
            tariffCodeByCompanyVM.onSearchCompany().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isBackFromDetail, tariffCodeByCompanyVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper`}>
          {/* <TariffCodeByCompanyTitleBar/>  */}
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowDetail?"30%":(isShowAddPanel?"60%":"100%")}
                rightSectionWidth={isShowDetail?"70%" : (isShowAddPanel?"40%":"0%")}
                leftChildren={<TariffCodeByCompanyTablePanel/>}
                rightChildren={isShowDetail?<TariffCodeByCompanyDetailPanel/> : <TariffCodeByCompanyFormPanel/>} 
                />
        </div>
    </>
}

export default memo(TariffCodeByCompanyMaintenance);