import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyTariffCodeEntity } from "domain/entity/TariffCode/CompanyTariffCodeEntity";
import _ from "lodash";
import { INITIAL_TARIFF_CODE_BY_COMP_DTL_COL_DEF } from "presentation/constant/TariffCodeByCompany/TariffCodeByCompanyDetailColumnDefinition";
import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";

const TariffCodeByCompanyDetailTablePanel: React.FC = () => {
    const [tariffCodeState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, detailState } = tariffCodeState;
    // const {selecedRows} = detailState;
    let gridRef: any = useRef();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CompanyTariffCodeEntity[]>([]);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        setOnTableSelectionClicked(true);
        tariffCodeByCompanyVM.updateSelectedDetailRows(selectedRows);
    }, [tariffCodeByCompanyVM])

    const handleRowDoubleClick = useCallback((tarCodeComp: CompanyTariffCodeEntity) => {
        tariffCodeByCompanyVM.onDetailRowDoubleClick(tarCodeComp);
    }, [tariffCodeByCompanyVM])


    // const handleCloseClick = useCallback(() => {
    //     tariffCodeByCompanyVM.onCloseDetail();
    // },[tariffCodeByCompanyVM])

    // const handleRowDrag = useCallback( (e: RowDragEvent, updatedRows: CompanyTariffCodeEntity[], movedIndex: number, overIndex: number)=>{
    // const { node } = e;
    // const updatedRowData = companyTariffCodes;
    // const movedRow = node.data;
    // let newMovedIndex = updatedRowData.indexOf(movedRow);
    // if(newMovedIndex === -1){
    //     newMovedIndex = updatedRowData.findIndex(row => row.id === movedRow.id);
    // }
    // updatedRowData.splice(newMovedIndex, 1);
    // updatedRowData.splice(overIndex, 0, movedRow);

    //     tariffCodeByCompanyVM.onDetailRowDrag(updatedRows);
    // },[tariffCodeByCompanyVM])

    // const handleApplyClick = useCallback(async () => {

    //     if(_.isEmpty(selecedRows)) return;
    //     setLoading(true);
    //     const res = await tariffCodeByCompanyVM.onDetailApply(selecedRows);
    //     setLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining('Apply failed.');
    //     }else{
    //         await tariffCodeByCompanyVM.onSearchCompanyTariffCode(currentSelectedRow)
    //     }
    //     setLoading(false);
    // },[currentSelectedRow, messageBarVM, tariffCodeByCompanyVM, selecedRows])


    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return detailState.selecedRows;
    }, [detailState.selecedRows]);

    const getShowMoveCursor = useCallback(() => {
        return showMoveCursor;
    }, [showMoveCursor]);
    //for right click menu with move after end

    useEffect(() => {
        const columnDefs = (INITIAL_TARIFF_CODE_BY_COMP_DTL_COL_DEF.slice());

        if (!detailState.selecedRows || detailState.selecedRows.length <= 0) {
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
        changeCursor("TariffCodeByCompanyDetail-table", getShowMoveCursor());
    });

    useEffect(() => {
        if (!onTableSelectionClicked) return;
        gridRef?.current?.gridRef.current.api?.deselectAll();
        changeCursor("TariffCodeByCompanyDetail-table", getShowMoveCursor());
    }, [getShowMoveCursor, onTableSelectionClicked]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(detailState.selecedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, detailState.selecedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        tariffCodeByCompanyVM.updateSelectedDetailRows([]);
        setShowMoveCursor(true);
    }, [tariffCodeByCompanyVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(tariffCodeState.companyTariffCodes);
        tariffCodeByCompanyVM.updateSelectedDetailRows([]);
        setShowMoveCursor(false);
    }, [tariffCodeState.companyTariffCodes, tariffCodeByCompanyVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setLoading(true);

        tariffCodeByCompanyVM.onDetailApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showWarining('Move failed.');
                setInitialAllRows(true);
                setLoading(false);

                tariffCodeByCompanyVM.updateSelectedDetailRows([]);
                setShowMoveCursor(false);
            } else {
                tariffCodeByCompanyVM.onSearchCompanyTariffCode(currentSelectedRow).then((data) => {
                    setInitialAllRows(true);
                    setLoading(false);

                    tariffCodeByCompanyVM.updateSelectedDetailRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setLoading(false);

                    tariffCodeByCompanyVM.updateSelectedDetailRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [currentSelectedRow, messageBarVM, tariffCodeByCompanyVM]);

    // const tbBtns = useMemo(()=>{
    //     let btns = [];
    //     btns.push( {
    //         id: 'onCloseButton',
    //         icon: 'Icon-cross',
    //         title: 'Close'
    //     })
    //     if(!_.isEmpty(selecedRows)){
    //         btns.push({
    //             id: 'onTickButton',
    //             icon: 'Icon-tick',
    //             title: 'Save'
    //         })
    //     }
    //     return btns;
    // },[selecedRows])

    const memoTariffCodeComponentTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="seq" />}
                <div id="myDiv">
                    <HPHTable
                        id='TariffCodeByCompanyDetail-table'
                        headerLabel={currentSelectedRow.companyCode}
                        columns={INITIAL_TARIFF_CODE_BY_COMP_DTL_COL_DEF}
                        data={allRows ?? []}
                        // headerActionButtons={tbBtns}
                        // onCloseButton={handleCloseClick}
                        // onTickButton={handleApplyClick}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={false}
                        // rowSelection={"multiple"}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        // isRowDrag={true}
                        // onRowDragged={handleRowDrag} 
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        onBodyScroll={(e: any) => changeCursor("TariffCodeByCompanyDetail-table", getShowMoveCursor())}
                    />
                </div>
            </>);
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, currentSelectedRow.companyCode, allRows, handleSelectionChange, handleRowDoubleClick, getShowMoveCursor])

    useEffect(() => {
        if (initialAllRows && tariffCodeState.companyTariffCodes && !_.isEmpty(tariffCodeState.companyTariffCodes)) {
            setAllRows(tariffCodeState.companyTariffCodes.map((companyTariffCodeEntity, index) => ({
                ...companyTariffCodeEntity,
                index: index || 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, tariffCodeState.companyTariffCodes]);


    return <>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {<TableWrapper>{memoTariffCodeComponentTable}</TableWrapper>}
    </>;
}

export default memo(TariffCodeByCompanyDetailTablePanel);