import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM";
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffCodeByCompanyDetailTablePanel from "../Table/TariffCodeByCompanyDetailTablePanel";
import { TariffCodeByCompanyDetailFormPanel } from "./TariffCodeByCompanyDetailFormPanel";

const TariffCodeByCompanyMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffCodeByCompanyState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const {detailState} = tariffCodeByCompanyState;
    const {isEditable,isSliderOpen} = detailState
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    tariffCodeByCompanyVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => { 
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        })
    }, [tariffCodeByCompanyVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper`}>
           {/* <TariffCodeByCompanyTitleBar/> */}
            <SliderPanel
                isOpen={isSliderOpen}
                draggable={false}
                leftSectionWidth={isEditable?"50%":"100%"}
                rightSectionWidth={"50%"}
                leftChildren={<TariffCodeByCompanyDetailTablePanel/>}
                rightChildren={<TariffCodeByCompanyDetailFormPanel/>} 
                />
        </div>
    </>
}

export default memo(TariffCodeByCompanyMaintenance);